import React, { Fragment, useEffect, useState } from "react";
import ReactDOM, { render } from "react-dom";

import useClient from "../components/Client";

import { hashidEncode } from "../helpers";
import ExportTable from "../components/Table/ExportTable";

import { ExportToCsv } from "export-to-csv";

import { makeStyles } from "@material-ui/styles";

import moment from "moment";

import MainDatePicker from "../components/Form/MainDatePicker";
import TextButton from "../components/Buttons/TextButton";

import AddOutlinedIcon from "@mui/icons-material/AddOutlined";

import {
    Paper,
    Grid,
    Select,
    MenuItem,
    Typography,
    List,
    ListItem,
    ListItemText,
    Link,
} from "@mui/material";

const Index = ({ userType, statusList }) => {
    console.log("statusList:", JSON.parse(statusList));
    console.log("userType:", JSON.parse(userType));
    const useStyles = makeStyles((theme) => ({
        paperCustom: {
            marginTop: -15,
            borderRadius: 30,
            boxShadow: "0px 9x 15px 0px #494949",
            border: 0,
            paddingLeft: 20,
            paddingRight: 20,
            paddingTop: 10,
            backgroundColor: "white",
            "@global": {
                "*::-webkit-scrollbar": {
                    width: "0.4em",
                    height: "0.4em",
                    backgroundColor: "#F5F5F5",
                },
                "*::-webkit-scrollbar-track": {
                    "-webkit-box-shadow": "inset 0 0 6px rgba(0,0,0,0.3)",
                    borderRadius: 10,
                    backgroundColor: "#F5F5F5",
                },

                "*::-webkit-scrollbar-thumb": {
                    "-webkit-box-shadow": "inset 0 0 6px rgba(0,0,0,.3)",
                    backgroundColor: " #5e5c5c",
                },
                ".MuiTablePagination-menuItem": {
                    fontSize: "12px",
                    color: "red",
                },
            },
        },
        gridCustom: {
            display: "flex",
        },
        "& .MuiTouchRipple-root": {
            padding: 0,
        },
        "& .MuiInputBase-formControl": {
            fontSize: "12px",
        },
    }));
    const client = useClient();
    const classes = useStyles();
    const [startDate, setStartDate] = useState(null); //date needed
    const [endDate, setEndDate] = useState(null); //date needed
    const [status, setStatus] = useState("active");
    const [search, setSearchValue] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const [tableData, setTableData] = useState([]);
    const [exportVisibleHeader, setExportVisibleHeader] = useState([]);

    const [typeOfUser, setTypeOfUser] = useState(JSON.parse(userType));
    const [statusListOptions, setStatusListOptions] = useState(
        JSON.parse(statusList)
    );
    const screenTitle = "Non-LOGs",
        screenSubTitle = "(Click on a status to view Non-LOG Details)",
        dateFrom = "Date needed from",
        dateTo = "To",
        createButtonLabel = "New Non-LOG",
        url = "/cis/med/non-loa-list/nonLoa_list",
        exportCurrentPage = "Current Page",
        exportAllResult = "All Data",
        exportFileName = "Shiphealth-Inc-Summary-of-Non-LOGs_",
        exportTitleName = "Shiphealth Inc Summary of Non-LOGs ";

    const NumberOfRowsPerPage = [20, 50, 100];

    const menuItems = [
        {
            label: "Active",
            value: "active",
        },
        {
            label: "Active & Closed",
            value: "active_and_closed",
        },
        {
            label: "Closed",
            value: "closed",
        },
    ];

    const forInternalUsersCols = [
        {
            accessorFn: (row) =>
                `${row.patient_name}` ? `${row.patient_name}` : ``,
            header: "Patient Name",
            id: "patient_name",
            size: 100,
            filterFn: "includesString",
            Cell: ({ cell, renderedCellValue, row }) => {
                return (
                    <Link
                        sx={{
                            m: 0,
                            p: 0,
                            minWidth: 0,
                            fontSize: "12px",
                            textAlign: "left",
                            color: "black",
                            textDecoration: "none",
                        }}
                        href={
                            window.location.origin +
                            "/cis/med/patients/" +
                            hashidEncode(cell.row.original.patient_id)
                        }
                    >
                        {renderedCellValue}
                    </Link>
                );
            },
        },
        {
            accessorFn: (row) => (`${row.status}` ? `${row.status}` : ``),
            header: "Status",
            id: "status",
            size: 100,
            filterVariant: "select",
            filterSelectOptions: statusListOptions,
            Cell: ({ cell, renderedCellValue, row }) => {
                // console.log("-------------------");
                // console.log("row status: ", row.original.status);
                // console.log("row status color: ", row.original.status_color);
                // console.log("renderedCellValue: ", renderedCellValue);
                return (
                    <Grid
                        display="block"
                        justifyContent="flex-start"
                        alignItems="center"
                        sx={{
                            padding: 0,
                        }}
                    >
                        <TextButton
                            variant="contained"
                            sx={[
                                {
                                    pl: "5px",
                                    pt: "3px",
                                    pb: "1px",
                                    pr: "5px",
                                    minHeight: 0,
                                    textTransform: "none",
                                    fontWeight: "bold",
                                    fontSize: "9px",
                                    textAlign: "center",
                                    borderRadius: "10px",
                                    color: "white",
                                },
                                row.original.status_color === "btn-primary" && {
                                    backgroundColor: "#3a79fc",
                                },
                                row.original.status_color === "btn-success" && {
                                    backgroundColor: "#3ca648",
                                },
                                row.original.status_color === "btn-danger" && {
                                    backgroundColor: "#d33f49",
                                },
                                row.original.status_color ===
                                    "btn-secondary" && {
                                    backgroundColor: "#7c868e",
                                },
                            ]}
                            href={
                                window.location.origin +
                                "/cis/med/non-loa-list/show/" +
                                hashidEncode(cell.row.original.case_id) +
                                "/" +
                                hashidEncode(cell.row.original.nonLoa_id)
                            }
                        >
                            {renderedCellValue}
                        </TextButton>
                    </Grid>
                );
            },
        },
        {
            accessorFn: (row) =>
                `${row.repat_date_string}` ? `${row.repat_date_string}` : ``,
            header: "Repat Date",
            id: "repat_date_string",
            size: 60,
            sortingFn: (rowA, rowB, columnId) => {
                var dateA = rowA.original.repat_date ?? "";
                // console.log("dateA:", dateA);
                var dateB = rowB.original.repat_date ?? "";
                // console.log("dateB:", dateB);
                if (dateA !== "Invalid date" && dateB !== "Invalid date") {
                    return dateA > dateB ? 1 : -1;
                }
            },
            filterFn: "includesString",
        },
        {
            accessorFn: (row) =>
                `${row.requestor_name}` ? `${row.requestor_name}` : ``,

            header: "Requested By",
            id: "requestor_name",
            size: 100,
            sortingFn: (rowA, rowB, columnId) => {
                var dateA = rowA.original.requested_date ?? "";
                // console.log("dateA:", dateA);
                var dateB = rowB.original.requested_date ?? "";
                // console.log("dateB:", dateB);
                if (dateA !== "Invalid date" && dateB !== "Invalid date") {
                    return dateA > dateB ? 1 : -1;
                }
            },
            filterFn: "includesString",
            Cell: ({ cell, renderedCellValue, row }) => {
                return (
                    <Fragment sx={{ paddingTop: 1 }}>
                        <b>{renderedCellValue}</b>
                        <br />
                        <i>
                            {row.original.requestor_name
                                ? row.original.requested_date_string
                                : ""}
                        </i>
                    </Fragment>
                );
            },
        },
        {
            accessorFn: (row) => (`${row.nonLoa_id}` ? `${row.nonLoa_id}` : ``),
            header: "Non-LOG ID",
            id: "nonLoa_id",
            size: 60,
            filterFn: "startsWith",
            Cell: ({ cell, renderedCellValue, row }) => {
                return (
                    <Fragment>
                        <Link
                            sx={{
                                minWidth: 0,
                                fontSize: "12px",
                                color: "black",
                                padding: 0,
                                margin: 0,
                                textDecoration: "none",
                            }}
                            href={
                                window.location.origin +
                                "/cis/med/non-loa-list/show/" +
                                hashidEncode(cell.row.original.case_id) +
                                "/" +
                                hashidEncode(cell.row.original.nonLoa_id)
                            }
                        >
                            {renderedCellValue}
                        </Link>
                    </Fragment>
                );
            },
        },
        {
            accessorFn: (row) =>
                `${row.date_needed_string}` ? `${row.date_needed_string} ` : ``,
            header: "Date Needed",
            id: "date_needed_string",
            size: "70",
            sortingFn: (rowA, rowB, columnId) => {
                var dateA = rowA.original.date_needed ?? "";
                // console.log("dateA:", dateA);
                var dateB = rowB.original.date_needed ?? "";
                // console.log("dateB:", dateB);
                if (dateA !== "Invalid date" && dateB !== "Invalid date") {
                    return dateA > dateB ? 1 : -1;
                }
            },
            filterFn: "startsWith",
        },
        {
            accessorFn: (row) => (`${row.case_id}` ? `${row.case_id} ` : ``),
            header: "Case ID",
            id: "case_id",
            size: "70",
            filterFn: "startsWith",
            Cell: ({ cell, renderedCellValue, row }) => {
                return (
                    <Fragment>
                        <Link
                            sx={{
                                minWidth: 0,
                                fontSize: "12px",
                                color: "black",
                                padding: 0,
                                margin: 0,
                                textDecoration: "none",
                            }}
                            href={
                                window.location.origin +
                                "/cis/med/cases/" +
                                hashidEncode(cell.row.original.case_id)
                            }
                        >
                            {renderedCellValue}
                        </Link>
                    </Fragment>
                );
            },
        },
        {
            accessorFn: (row) =>
                `${row.f2fOrTelemedValue}` ? `${row.f2fOrTelemedValue} ` : ``,
            header: "Face to Face / Telemedicine",
            id: "f2fOrTelemedValue",
            size: "70",
            filterVariant: "select",
            filterSelectOptions: ["Face to Face", "Telemedicine"],
        },
        {
            accessorFn: (row) => (`${row.purpose}` ? `${row.purpose} ` : ``),
            header: "Purpose",
            id: "purpose",
            size: "70",
            filterFn: "startsWith",
        },
    ];

    const tableHeaders = forInternalUsersCols;
    // useEffect(() => {
    //     console.log("table data:", JSON.stringify(tableData));
    //     console.log("statuslistoptions:", JSON.stringify(statusListOptions));
    //     console.log("userType:", JSON.stringify(userType));
    // }, [tableData, statusListOptions, userType]);
    useEffect(() => {
        client
            .get(`${url}/${startDate}/${endDate}/${status}/${search}`)
            .then((response) => {
                setTableData(response.data);
                setIsLoading(false);
                console.log(response.data);
            })
            .catch((err) => {
                console.log(err.message);
            });
    }, [startDate, endDate, status, search]);

    const forInternalUsersExportCols = [
        {
            HeaderName: "Patient Name",
            columnName: "patient_name",
        },
        {
            HeaderName: "Status",
            columnName: "status",
        },
        {
            HeaderName: "Repat Date",
            columnName: "repat_date_string",
        },
        {
            HeaderName: "Requested By",
            columnName: "requestor_name",
        },
        {
            HeaderName: "Non-LOG ID",
            columnName: "nonLoa_id",
        },
        {
            HeaderName: "Date Needed",
            columnName: "date_needed_string",
        },
        {
            HeaderName: "Case ID",
            columnName: "case_id",
        },
        {
            HeaderName: "Face to Face / Telemedicine",
            columnName: "f2fOrTelemedValue",
        },
        {
            HeaderName: "Purpose",
            columnName: "purpose",
        },
    ];

    const Names = forInternalUsersExportCols;

    const hiddenColumnsForInternalUsers = {};

    const hiddenColumns =
        typeOfUser.userType === "Internal" ? hiddenColumnsForInternalUsers : "";

    return (
        <>
            <Paper className={classes.paperCustom}>
                <Grid container>
                    <Grid
                        item
                        xs={12}
                        sm={12}
                        md={12}
                        sx={{ display: "flex", p: 1 }}
                    >
                        <Typography variant="h6">{screenTitle}</Typography>
                        <Typography
                            variant="subtitle2"
                            mt={1}
                            sx={{ padding: "0px 9px" }}
                        >
                            {screenSubTitle}
                        </Typography>
                    </Grid>
                    <Grid
                        item
                        xs={12}
                        sm={12}
                        md={12}
                        sx={{ p: 2, display: "flex" }}
                    >
                        <Grid item xs={12} sm={6} md={2}>
                            <MainDatePicker
                                id="start_date"
                                size="small"
                                variant="outlined"
                                name="start_date"
                                label={dateFrom}
                                fullWidth
                                value={startDate}
                                onChange={(element) => {
                                    setStartDate(
                                        element
                                            ? element.format("MM-DD-YYYY")
                                            : null
                                    );
                                    setIsLoading(true);
                                }}
                                sx={{
                                    ".MuiOutlinedInput-input": {
                                        fontSize: "12px",
                                        height: "25px",
                                        p: "4px 9px",
                                    },
                                    "& .MuiInputLabel-formControl": {
                                        fontSize: "12px",
                                    },
                                    "& .MuiInputLabel-shrink": {
                                        fontSize: "16px",
                                    },
                                }}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6} md={2} sx={{ ml: 2, mr: 2 }}>
                            <MainDatePicker
                                id="end_date"
                                size="small"
                                variant="outlined"
                                name="end_date"
                                label={dateTo}
                                fullWidth
                                value={endDate}
                                onChange={(element) => {
                                    setEndDate(
                                        element
                                            ? element.format("MM-DD-YYYY")
                                            : null
                                    );
                                    setIsLoading(true);
                                }}
                                minDate={startDate}
                                sx={{
                                    ".MuiOutlinedInput-input": {
                                        fontSize: "12px",
                                        height: "25px",
                                        p: "4px 9px",
                                    },
                                    "& .MuiInputLabel-formControl": {
                                        fontSize: "12px",
                                    },
                                    "& .MuiInputLabel-shrink": {
                                        fontSize: "16px",
                                    },
                                }}
                            />
                        </Grid>
                        {typeOfUser.userType === "Internal" && (
                            <Grid item xs={12} sm={2} md={1}>
                                <Select
                                    id="outlined-basic"
                                    variant="outlined"
                                    value={status}
                                    size="small"
                                    onChange={(element) => {
                                        setStatus(element.target.value);
                                        setIsLoading(true);
                                    }}
                                    sx={{
                                        " .MuiSelect-select": {},
                                        "& .MuiOutlinedInput-input": {
                                            fontSize: "12px",
                                            height: "20px",
                                            p: "6px 6px",
                                        },
                                    }}
                                >
                                    {menuItems.map((menuItem) => {
                                        return (
                                            <MenuItem
                                                key={menuItem.value}
                                                value={menuItem.value}
                                            >
                                                {menuItem.label}
                                            </MenuItem>
                                        );
                                    })}
                                </Select>
                            </Grid>
                        )}
                        <Grid
                            item
                            xs={12}
                            sm={5}
                            md={8}
                            sx={{
                                display: "flex",
                                justifyContent: "flex-end",
                            }}
                        >
                            <TextButton
                                id="new_button"
                                href={
                                    window.location.origin +
                                    "/cis/med/non-loa-list/create/"
                                }
                                sx={{
                                    backgroundColor: "#1976d2",
                                    color: "white",
                                    pl: 2,
                                    pr: 2,
                                    fontSize: "12px",
                                }}
                                size="small"
                            >
                                <AddOutlinedIcon fontSize="small" />
                                {createButtonLabel}
                            </TextButton>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} sm={12} md={12}>
                        <ExportTable
                            headers={tableHeaders}
                            data={tableData}
                            isloadingStatus={isLoading}
                            labelExportAllData={exportAllResult}
                            labelExportPerPage={exportCurrentPage}
                            perPage={NumberOfRowsPerPage}
                            Names={Names}
                            listOfHiddenColumn={hiddenColumns}
                            exportFileName={exportFileName}
                            exportTitleName={exportTitleName}
                            exportPermission={"true"}
                            rowHeight={0}
                            onGlobalSearchChange={(value) => {
                                if (value === "") {
                                    setSearchValue(null);
                                    setIsLoading(true);
                                } else {
                                    setSearchValue(value);
                                    setIsLoading(true);
                                }
                            }}
                        />
                    </Grid>
                </Grid>
            </Paper>
        </>
    );
};

export default Index;
if (document.getElementById("nonLoaIndex")) {
    var userType = document
        .getElementById("nonLoaIndex")
        .getAttribute("userType");
    var statusList = document
        .getElementById("nonLoaIndex")
        .getAttribute("statusList");
    // if (document.getElementById("nonLoaIndex")) {
    ReactDOM.render(
        <Index userType={userType} statusList={statusList} />,
        document.getElementById("nonLoaIndex")
    );
}
// }
